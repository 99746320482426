import React, { createContext, useContext, useMemo } from 'react';
import defineAbilitiesFor from './defineAbilities';

const AbilityContext = createContext();

export const AbilityProvider = ({ children, user }) => {
  const ability = useMemo(() => defineAbilitiesFor(user), [user]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const useAbility = () => useContext(AbilityContext);
