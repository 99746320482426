import { DataService } from '../config/dataService/dataService';

const getAllUsers = async () => {
  const { data } = await DataService.get('users');
  return data;
};

const getUser = async (id) => {
  const { data } = await DataService.get(`users/${id}`);
  return data;
};

const getUserExceptClients = async () => {
  const { data } = await DataService.get('users/exclude-clients');
  return data;
};

const getLogs = async () => {
  const { data } = await DataService.get(`logs`);
  return data;
};

const getAllClients = async () => {
  const { data } = await DataService.get('users/clients');
  return data;
};

const createUser = async (body) => {
  const { data } = await DataService.post('users', body);
  return data;
};

const updateUser = async (body) => {
  const { data } = await DataService.patch(`users/${body.id}`, body);
  return data;
};

const deleteUser = async (id) => {
  const { data } = await DataService.delete(`users/${id}`);
  return data;
};

export { getAllUsers, getUser, getUserExceptClients, createUser, updateUser, deleteUser, getAllClients, getLogs };
