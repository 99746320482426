export const queryKey = {
  USERS: 'users',
  USERS_EXCEPT_CLIENTS: 'user-except-clients',
  UNIQUE_DEPARTMENTS: 'unique-departments',
  UNIQUE_GROUPS: 'unique-groups-in-departments',
  DESIGNATIONS: 'designations',
  DEPARTMENTS: 'departments',
  ROLES: 'roles',
  USERS: 'users',
  USER: 'user',
  SCHEDULE: 'schedule',
  SCHEDULES: 'schedules',
  CLIENTS: 'clients',
  LOGS: 'logs',
  CLIENT_SCHEDULES: 'client-schedules',
  REPORTS: 'reports',
  SINGLE_INVOICE: 'single-invoice',
  EMPLOYEE_PERFORMANCE_REPORTS: 'employee-performance-reports',
  EMPLOYEE_PERFORMANCE_REPORT: 'employee-performance-report',
};
