import { AbilityBuilder, Ability } from '@casl/ability';
import { currentUser } from '../utility/utility';

export default function defineAbilitiesFor(user) {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if (!user || !user.role || !user.role.permissions) {
    // Define default abilities for non-authenticated users if needed
    return build();
  }

  user.role.permissions.forEach((permission) => {
    console.debug('🚀 ~ file: defineAbilities.js:8 ~ currentUser ~ permission:', permission);
    if (permission.canRead) {
      can('read', permission.module);
    }
    if (permission.canCreate) {
      can('create', permission.module);
    }
    if (permission.canUpdate) {
      can('update', permission.module);
    }
    if (permission.canDelete) {
      can('delete', permission.module);
    }
  });

  return build();
}
