import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Report = lazy(() => import('../../container/reports/Reports'));
const ClientSideReports = lazy(() => import('../../container/reports/ClientSideReports'));
const EmployeePerformanceReports = lazy(() => import('../../container/reports/EmployeePerformanceReports'));
const ReportTypes = lazy(() => import('../../container/reports/ReportTypes'));
const Invoice = lazy(() => import('../../container/reports/Invoice'));
const ViewEmployeePerformanceReport = lazy(() => import('../../container/reports/ViewEmployeePerformanceReport'));
const NotFound = lazy(() => import('../../container/pages/404'));

function ReportsRoute() {
  return (
    <Routes>
      {/* <Route path="products/*" element={<Product />} />
      <Route exact path="add-product" element={<ProductAdd />} />
      <Route exact path="edit-product" element={<ProductEdit />} />
      <Route exact path="productDetails/:id" element={<ProductDetails />} /> */}
      {/* <Route exact path="view-invoices" element={<ViewInvoices />} /> */}
      <Route exact path="/create-reports" element={<Report />} />
      <Route exact path="/client-side-reports" element={<ClientSideReports />} />
      <Route exact path="/employee-performance-reports" element={<EmployeePerformanceReports />} />
      <Route exact path="/report-types" element={<ReportTypes />} />
      <Route exact path="invoice" element={<Invoice />} />
      <Route exact path="employee-performance-report/:id" element={<ViewEmployeePerformanceReport />} />
      {/* <Route exact path="sellers" element={<Sellers />} />
      <Route path="cart/*" element={<Cart />} />
      <Route path="checkout/*" element={<Checkout />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default ReportsRoute;
