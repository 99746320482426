/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import useUserStore from '../store/userStore';

const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

const darkColors = [
  'rgb(75, 0, 130)', // Indigo (A)
  'rgb(0, 0, 139)', // Dark Blue (B)
  'rgb(139, 0, 139)', // Dark Magenta (C)
  'rgb(72, 61, 139)', // Dark Slate Blue (D)
  'rgb(47, 79, 79)', // Dark Slate Gray (E)
  'rgb(128, 0, 128)', // Purple (F)
  'rgb(0, 0, 128)', // Navy (G)
  'rgb(25, 25, 112)', // Midnight Blue (H)
  // Add colors for the rest of the letters...
];

const getColorForLetter = (letter) => {
  const index = letter.toUpperCase().charCodeAt(0) - 'A'.charCodeAt(0);
  return darkColors[index % darkColors.length]; // Ensure the index is within bounds
};

const getColorForName = (name) => {
  const firstLetter = name.charAt(0);
  return getColorForLetter(firstLetter);
};

const formatDate = (inputDate) => {
  const parsedDate = new Date(inputDate);
  const formattedDate = format(parsedDate, 'dd MMMM, yyyy');
  return formattedDate;
};
const formatDateTime = (inputDate) => {
  const parsedDate = new Date(inputDate);
  const formattedDate = format(parsedDate, 'dd MMMM yyyy hh:mm aaa');
  return formattedDate;
};

const PERMISSIONS = [
  {
    module: 'users',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    module: 'schedules',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    module: 'months',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    module: 'projects',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    module: 'logs',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    module: 'department',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    module: 'designation',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    module: 'roles',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    module: 'reports',
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
];

const highlightColors = [
  '#980000',
  '#ff0000',
  '#ff9900',
  '#ffff00',
  '#00ff00',
  '#00ffff',
  '#4a86e8',
  '#0000ff',
  '#9900ff',
  '#ff00ff',
  '#e6b8af',
  '#f4cccc',
  '#fce5cd',
  '#fff2cc',
  '#d9ead3',
  '#d0e0e3',
  '#c9daf8',
  '#cfe2f3',
  '#d9d2e9',
  '#ead1dc',
  '#dd7e6b',
  '#ea9999',
  '#f9cb9c',
  '#ffe599',
  '#b6d7a8',
  '#a2c4c9',
  '#a4c2f4',
  '#9fc5e8',
  '#b4a7d6',
  '#d5a6bd',
  '#cc4125',
  '#e06666',
  '#f6b26b',
  '#ffd966',
  '#93c47d',
  '#76a5af',
  '#6d9eeb',
  '#6fa8dc',
  '#8e7cc3',
  '#c27ba0',
];

// Function to determine the background color based on the first character
const getBackgroundColor = (internalName) => {
  if (!internalName) return 'bg-default'; // Default color if no internalName

  const firstChar = internalName.charAt(0).toUpperCase();

  const colorMap = {
    A: 'bg-primary',
    B: 'bg-secondary',
    C: 'bg-success',
    D: 'bg-danger',
    E: 'bg-warning',
    F: 'bg-info',
    G: 'bg-light',
    H: 'bg-dark',
    I: 'bg-custom1', // Custom colors in ClientAvatar.css
    J: 'bg-custom2',
    K: 'bg-custom3',
    L: 'bg-custom4',
    M: 'bg-custom5',
    N: 'bg-custom6',
    O: 'bg-custom7',
    P: 'bg-custom8',
    Q: 'bg-custom9',
    R: 'bg-custom10',
    S: 'bg-custom11',
    T: 'bg-custom12',
    U: 'bg-custom13',
    V: 'bg-custom14',
    W: 'bg-custom15',
    X: 'bg-custom16',
    Y: 'bg-custom17',
    Z: 'bg-custom18',
  };

  return colorMap[firstChar] || 'bg-default'; // Fallback color if no match
};

function isNumeric(str) {
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

const ratingInfo = [
  {
    heading: 'Time Management:',
    para: 'How effectively deadlines and timelines are handled.',
  },
  {
    heading: 'Accuracy:',
    para: 'Precision in estimates and avoiding errors.',
  },
  {
    heading: 'Detailing:',
    para: 'Thoroughness in capturing project specifics and requirements.',
  },
  {
    heading: 'Documentation Quality:',
    para: 'Clarity and completeness of submitted reports and paperwork.',
  },
  {
    heading: 'Decision-Making:',
    para: 'Ability to make sound judgments and resolve issues effectively.',
  },
];
const complexityInfo = [
  {
    heading: 'Basic:',
    para: 'Simple tasks with clear guidelines, requiring minimal expertise.',
  },
  {
    heading: 'Moderate:',
    para: 'Mid-level tasks with some variability, needing problem-solving.',
  },
  {
    heading: 'Advanced:',
    para: 'Complex tasks demanding expertise and independent decision-making.',
  },
  {
    heading: 'Innovative:',
    para: 'Cutting-edge solutions, involving creative and strategic thinking.',
  },
];

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Function to generate month-year options
const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2020;
  const endYear = 2030;

  for (let year = startYear; year <= endYear; year++) {
    for (let month = 0; month < months.length; month++) {
      const monthName = months[month];
      options.push({
        value: `${monthName} - ${year}`,
        label: `${monthName} - ${year}`,
      });
    }
  }

  return options;
};

export {
  ellipsis,
  idGenerator,
  formatDate,
  formatDateTime,
  PERMISSIONS,
  getColorForName,
  getBackgroundColor,
  isNumeric,
  ratingInfo,
  complexityInfo,
  highlightColors,
  months,
  generateMonthYearOptions,
};
