import React, { useEffect, useState, lazy } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AbilityProvider } from './ability/AbilityProvider';
import useCurrentUser from './hooks/currentUser';
import './static/css/style.css';
import 'antd/dist/antd.less';

const NotFound = lazy(() => import('./container/pages/404'));

const { theme } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? (
            <Routes>
              <Route path="/*" element={<Auth />} />{' '}
            </Routes>
          ) : (
            <Routes>
              <Route path="/dashboard/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard/schedule" />} />
            </Routes>
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      throwOnError: (err) => {
        message.error(err.message);
      },
    },
  },
});

function App() {
  const currentUser = useCurrentUser();
  console.debug('🚀 ~ file: App.js:80 ~ App ~ currentUser:', currentUser);

  return (
    <AbilityProvider user={currentUser || null}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ProviderConfig />
        </QueryClientProvider>
      </Provider>
    </AbilityProvider>
  );
}

export default App;
