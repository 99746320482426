import React, { useEffect, useState } from 'react';
import { Row, Col, Collapse, Spin, Button } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { GlobalUtilityStyle, ChangelogWrapper } from '../styled';
import versions from '../../demoData/changelog.json';
import { useQuery } from '@tanstack/react-query';
import { queryKey } from '../../config/queryKeys';
import { getLogs } from '../../services/userServices';
import { formatDateTime } from '../../utility/utility';
import { format, parseISO, isValid } from 'date-fns';

const { Panel } = Collapse;

function ChangeLog() {
  const { data: changeLog, isPending: isLoadingLog } = useQuery({ queryKey: queryKey.LOGS, queryFn: getLogs });
  // const [logs, setLogs] = useState([]);

  const PageRoutes = [
    {
      path: '/admin',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '',
      breadcrumbName: 'Changelog',
    },
  ];

  // useEffect(() => {
  //   if (isSuccess) {
  //     const formattedLogs = processLogs(changeLog.data);
  //     setLogs(formattedLogs);
  //   }
  // }, [isSuccess]);

  const getOperationColors = (status) => {
    if (status === 'update') {
      return 'bg-success';
    } else if (status === 'delete') {
      return 'bg-red-600';
    }
  };

  function mergeChanges(changesArray) {
    return changesArray.reduce((acc, curr) => {
      for (const [key, value] of Object.entries(curr)) {
        if (key === '_id') continue; // Skip _id as it remains the same
        if (acc[key]) {
          // Merge old and new values for each field
          if (typeof value === 'object') {
            acc[key] = {
              old: value.old || acc[key].old,
              new: value.new || acc[key].new,
            };
          } else {
            acc[key] = value;
          }
        } else {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  }

  const aggregatedData = changeLog?.data?.reduce((acc, curr) => {
    const key = `${curr?.documentId}-${curr?.changedBy?._id}`;

    if (!acc[key]) {
      acc[key] = {
        _id: curr?.documentId,
        changedBy: curr?.changedBy,
        changes: {},
        operation: curr?.operation,
        createdAt: curr?.createdAt,
      };
    }
    if (curr?.operation === 'update') {
      acc[key].changes = mergeChanges([acc[key].changes, curr.changes]);
    }
    return acc;
  }, {});

  // const result = Object.values(aggregatedData);

  const isID = (value) => {
    // Function to determine if a value is likely an ID
    // You can adjust this check based on your ID formats
    return typeof value === 'string' && /^[a-fA-F0-9]{24}$/.test(value);
  };

  // Function to check if a value is a date string
  const isDate = (value) => {
    // Try to parse the value as an ISO date
    if (typeof value === 'string') {
      const parsedDate = parseISO(value);
      return isValid(parsedDate);
    }
    return false;
  };

  // Function to format date values
  const formatDate = (value) => {
    try {
      return format(parseISO(value), 'dd.MM.yyyy');
    } catch (error) {
      return value; // Return as-is if parsing fails
    }
  };

  const formatChanges = (changes) => {
    return Object.entries(changes).reduce((acc, [key, value]) => {
      // Skip IDs and unchanged values
      if (key === '_id' || isID(value.old) || isID(value.new) || value.old === value.new) {
        return acc;
      }

      // Format date values if applicable
      const oldValue = isDate(value.old) ? formatDate(value.old) : value.old;
      const newValue = isDate(value.new) ? formatDate(value.new) : value.new;

      acc.push({
        field: key,
        oldValue: oldValue,
        newValue: newValue,
      });

      return acc;
    }, []);
  };
  function camelCaseToNormal(camelCaseString) {
    return (
      camelCaseString
        // Insert a space before each uppercase letter
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // Capitalize the first letter of each word
        .replace(/\b\w/g, (char) => char.toUpperCase())
    );
  }

  if (isLoadingLog) {
    return (
      <div className="spin flex items-center justify-center h-[calc(100vh-132px)]">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Changelog"
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 xl:px-[15px] pb-[50px] ssm:pb-[30px]">
        <GlobalUtilityStyle>
          {/* <Row gutter={25}> */}
          <Col>
            <ChangelogWrapper>
              <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
                {/* <div className="h-[60px] ssm:h-auto px-[25px] text-dark dark:text-white87 font-medium text-[17px] border-regular dark:border-white10 border-b">
                  <h1 className="mb-0 inline-flex items-center flex-wrap py-[16px] text-[18px] font-semibold gap-[15px]">
                    <span className="text-lg font-medium text-dark dark:text-white87">
                      Version {versions[0].version}{' '}
                    </span>
                    <span className="mx-2 text-lg font-medium text-dark dark:text-white87">-</span>
                    <span className="text-[#868eae] dark:text-white60 text-base font-normal">{versions[0].date}</span>
                  </h1>
                </div> */}
                <div className="p-[25px]">
                  {/* {versions[0].new && (
                    <div className="mb-[30px]">
                      <span className="inline-block bg-success mb-4 px-2 py-[5px] text-white dark:text-white87 text-xs font-medium leading-4 tracking-[1.4px] rounded">
                        New
                      </span>
                      <ul className="mb-0">
                        {versions[0].new.map((item, key) => {
                          return (
                            <li
                              key={key + 1}
                              className="relative ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-success after:rounded-full"
                            >
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )} */}
                  {/* {versions[0].fixed && (
                    <div className="mb-[30px]">
                      <span className="inline-block bg-secondary mb-4 px-2 py-[5px] text-white dark:text-white87 text-xs font-medium leading-4 tracking-[1.4px] rounded">
                        Fixed
                      </span>
                      <ul className="mb-0">
                        {versions[0].fixed.map((item, key) => {
                          return (
                            <li
                              key={key + 1}
                              className="relative ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-info after:rounded-full"
                            >
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )} */}
                  {/* {versions[0].updated && ( */}
                  {!!aggregatedData &&
                    Object.values(aggregatedData)
                      .filter((item) => item.operation !== 'create')
                      .map((item, index) => {
                        return (
                          <>
                            <div key={index} className="">
                              <span
                                className={`inline-block ${getOperationColors(
                                  item.operation,
                                )} mb-4 px-2 py-[5px] text-white dark:text-white87 text-xs font-medium leading-4 tracking-[1.4px] rounded`}
                              >
                                {item.operation?.toUpperCase()}
                              </span>
                              {/* <ul className="mb-0"> */}
                              {/* {versions[0].updated.map((item, key) => { */}
                              {/* return ( */}
                              {/* <li
                          key={index + 1}
                          className="relative ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-primary after:rounded-full"
                        >
                          {item}
                        </li> */}
                              {/* ); */}
                              {/* })} */}
                              {/* </ul> */}
                            </div>
                            <Collapse
                              accordion
                              className="changelog-accordion bg-transparent mt-[10px] mb-[20px] border-10"
                            >
                              {/* {versions.map((version, index) => { */}
                              {/* return ( */}
                              {/* index >= 1 && ( */}
                              <Panel
                                className="border-regular border-10 dark:border-white10 rounded-6 dark:bg-white10 "
                                header={
                                  <>
                                    <span className="text-lg font-medium text-dark dark:text-white87">
                                      {item?.operation === 'update'
                                        ? `${item?.changes?.projectName?.new || 'Project Name'} by ${
                                            item?.changedBy?.name
                                          } at ${formatDateTime(item?.createdAt)}`
                                        : `${item?.changes?.projectName?.new || 'Project Name'} by ${
                                            item?.changedBy?.name
                                          } at ${formatDateTime(item?.createdAt)}`}
                                    </span>
                                    {/* <span className="text-base font-normal text-dark dark:text-white87">
                                {item.createdAt}
                              </span> */}
                                  </>
                                }
                              >
                                <div>
                                  <div>
                                    <ul>
                                      {formatChanges(item?.changes).map((change, key) => {
                                        return (
                                          <div key={key} className="border-1 my-4 px-4 py-3">
                                            <li className="relative mt-3 ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-success after:rounded-full">
                                              <strong>Field:</strong> {camelCaseToNormal(change?.field)}
                                            </li>
                                            <li
                                              className={`relative ${
                                                !!!change.oldValue && 'flex flex-row'
                                              } mt-3 ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-success after:rounded-full`}
                                            >
                                              <strong>Old Value:</strong> {'  '}
                                              {change?.oldValue || <p className="italic"> empty</p>}
                                            </li>
                                            <li
                                              className={`relative ${
                                                !!!change.newValue && 'flex flex-row'
                                              } mt-3 ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-success after:rounded-full`}
                                            >
                                              <strong>New Value:</strong>{' '}
                                              {change?.newValue || <p className="italic"> empty</p>}
                                            </li>
                                          </div>
                                        );
                                      })}
                                      {/* {formatChanges(item?.changes).map((change, key) => {
                                        return (
                                          <>
                                            <li
                                              key={key + 1}
                                              className="relative mt-3 ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-success after:rounded-full"
                                            >
                                              <strong>Field:</strong> {camelCaseToNormal(change.field)}
                                            </li>
                                            <li
                                              key={key + 1}
                                              className={`relative ${
                                                !!!change.oldValue && 'flex flex-row'
                                              } mt-3 ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-success after:rounded-full`}
                                            >
                                              <strong>Old Value:</strong>
                                              {'  '}
                                              {change.oldValue || <p className="italic"> empty</p>}
                                            </li>
                                            <li
                                              key={key + 1}
                                              className={`relative 
                                            ${
                                              !!!change.newValue && 'flex flex-row '
                                            } mt-3 ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-success after:rounded-full`}
                                            >
                                              <strong>New Value:</strong>{' '}
                                              {change.newValue || <p className="italic"> empty</p>}
                                            </li>
                                          </>
                                        );
                                      })} */}
                                    </ul>
                                  </div>
                                  {/* {version.new && (
                              <div>
                                <span className="inline-block bg-success mb-4 px-2 py-[5px] text-white dark:text-white87 text-xs font-medium leading-4 tracking-[1.4px] rounded">
                                  New
                                </span>
                                <ul>
                                  {version.new.map((item, key) => {
                                    return (
                                      <li
                                        key={key + 1}
                                        className="relative ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-success after:rounded-full"
                                      >
                                        {item}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                            {version.fixed && (
                              <div>
                                <span className="inline-block bg-info mb-4 px-2 py-[5px] text-white dark:text-white87 text-xs font-medium leading-4 tracking-[1.4px] rounded">
                                  Fixed
                                </span>
                                <ul>
                                  {version.fixed.map((item, key) => {
                                    return (
                                      <li
                                        key={key + 1}
                                        className="relative ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-info after:rounded-full"
                                      >
                                        {item}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                            {version.updated && (
                              <div>
                                <span className="inline-block bg-secondary mb-4 px-2 py-[5px] text-white dark:text-white87 text-xs font-medium leading-4 tracking-[1.4px] rounded">
                                  Updated
                                </span>
                                <ul>
                                  {version.updated.map((item, key) => {
                                    return (
                                      <li
                                        key={key + 1}
                                        className="relative ltr:pl-5 rtl:pr-5 text-body dark:text-white60 text-base after:absolute ltr:after:left-0 rtl:after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-1.5 after:h-1.5 after:bg-primary after:rounded-full"
                                      >
                                        {item}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )} */}
                                </div>
                              </Panel>
                              {/* ) */}
                              {/* ); */}
                              {/* })} */}
                            </Collapse>
                          </>
                        );
                      })}
                </div>
              </div>
            </ChangelogWrapper>
          </Col>
          {/* <Col xxl={5} md={8} xs={24}>
              <div className="bg-white dark:bg-white10 m-0 p-0 text-theme-gray dark:text-white60 text-[15px] mb-[25px] rounded-10 relative">
                <div className="h-[60px] px-[25px] text-dark dark:text-white87 font-medium text-[17px] border-regular dark:border-white10 border-b">
                  <h1 className="mb-0 inline-block py-[16px] overflow-hidden whitespace-nowrap text-ellipsis text-[18px] font-semibold">
                    Changelog
                  </h1>
                </div>
                <div className="p-[25px]">
                  <h4 className="mb-6 text-dark dark:text-white87 text-[11px] font-medium uppercase">
                    VERSION HISTORY
                  </h4>
                  <ul className="mb-0">
                    {versions.map((version) => {
                      return (
                        <li key={version.id} className="flex justify-between mb-6 last:mb-0">
                          <span className="text-sm font-medium text-dark dark:text-white87">
                            Version {version.version}
                          </span>
                          <span className="text-sm text-body dark:text-white60">{version.date}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Col> */}
          {/* </Row> */}
        </GlobalUtilityStyle>
      </main>
    </>
  );
}

export default ChangeLog;
