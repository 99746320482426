import Cookies from 'js-cookie';
import useUserStore from '../store/userStore';

const useCurrentUser = () => {
  const { user } = useUserStore();
  const cookieUser = Cookies.get('user');

  switch (true) {
    case !!user:
      return user;
    case !!cookieUser:
      return JSON.parse(cookieUser);
    default:
      return null;
  }
};

export default useCurrentUser;
